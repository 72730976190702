export const setSingleCategory = (category) => {
     return {
        type: "SET_SINGLE_CATEGORY",
        category: category,
     }
}

export const getSingleCategory = () => {
    return {
        type: "GET_SINGLE_CATEGORY",
    };
};
