import React, {useState} from "react";
import { useForm } from "react-hook-form";
import { motion } from "framer-motion";
import { Button } from "../Components/ui/button";
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "../Components/ui/dialog";
import { Input } from "../Components/ui/input";
import { Label } from "../Components/ui/label";
import { baseURL } from "../Api";
import {useSelector} from "react-redux";
import toast, { Toaster } from 'react-hot-toast';
import { Rings } from 'react-loader-spinner'
import axios from "axios";


const ResetDialog = () => {
    const { register, handleSubmit, formState: { errors }, watch } = useForm();
    const user = useSelector(state => state?.user)
    const [Loading, setLoading] = useState(false);

    const onSubmit = async (data) => {
        const payLoad = {
            old_password: data?.oldPassword,
            new_password: data?.newPassword,
            confirm_password: data?.confirmPassword
        }
       try {
            setLoading(true)
           if (user && user?.token) { // Check if user is not null and has a token
               const res = await axios.put(`${baseURL}account/password/change/`, payLoad,{
                   headers: { Authorization: `Token ${user?.token}` }
               });

               if (res?.status === 200) {
                   setLoading(false)
                 toast.success('Password changed successfully.');
               } else {
                   setLoading(false)
                   toast.error('Password changed failed' || res?.data.message);
               }
           }
       } catch (error) {
           setLoading(false)
           toast.error('Password changed failed' || error?.response?.data?.message);
           console.log("this is the Error", error);
       }
    };

    const newPassword = watch("newPassword");

    return (
        <Dialog>
            <DialogTrigger asChild>
                <motion.div
                    className='bg-white p-5 rounded-[35px] mt-5 flex justify-center hover:bg-red-500 hover:text-white items-center hover:cursor-pointer'>
                    <p className='font-bold'>Change Password</p>
                </motion.div>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px] box border-0 text-white px-10">
                <DialogHeader>
                    <DialogTitle>Change Password</DialogTitle>
                </DialogHeader>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid  gap-4 py-4">
                        <div className="flex flex-col gap-4">
                            <Label htmlFor="oldPassword">Old Password</Label>
                            <Input
                                id="oldPassword"
                                {...register("oldPassword", { required: "Old password is required" })}
                                placeholder="Enter Old Password"
                                className={'text-black'}
                            />
                            {errors.oldPassword && <span className="text-red-500">{errors.oldPassword.message}</span>}
                        </div>
                        <div className="flex flex-col gap-4">
                            <Label htmlFor="newPassword">New Password</Label>
                            <Input
                                id="newPassword"
                                {...register("newPassword", {
                                    required: "New password is required",
                                    minLength: {
                                        value: 6,
                                        message: "New password must be at least 6 characters"
                                    }
                                })}
                                className={'text-black'}
                                placeholder="Enter New Password"
                            />
                            {errors.newPassword && <span className="text-red-500">{errors.newPassword.message}</span>}
                        </div>
                        <div className="flex flex-col gap-4">
                            <Label htmlFor="confirmPassword">Confirm New Password</Label>
                            <Input
                                id="confirmPassword"
                                {...register("confirmPassword", {
                                    required: "Confirm password is required",
                                    validate: value => value === newPassword || "Passwords do not match"
                                })}
                                className={'text-black'}
                                placeholder="Enter Confirm Password"
                            />
                            {errors.confirmPassword && <span className="text-red-500">{errors.confirmPassword.message}</span>}
                        </div>
                    </div>
                    <DialogFooter>
                        <Button type="submit">
                            {Loading ? (
                               <Rings
                                   visible={true}
                                   height="80"
                                   width="80"
                                   color="#4fa94d"
                                   ariaLabel="rings-loading"
                               />
                            ) : (
                                'Save changes'
                            )}
                        </Button>
                    </DialogFooter>
                </form>
            </DialogContent>

            <Toaster  position={"top-right"} />
        </Dialog>
    );
}

export default ResetDialog;
