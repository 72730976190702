import React from 'react'
import ResetPassword from '../component/ResetPassword'

const AuthReset = () => {
  return (
    <div className='w-screen h-screen bg-black overflow-auto'>
    <div className='justify-center items-center flex mt-10'>
        <ResetPassword />
    </div>

    {/* Footer */}
    <div className='box mt-[2.0rem] h-[100px] w-[90vw] ml-5 rounded-lg flex justify-center items-center  md:w-[90vw] md:h-[65px] md:rounded-full md:mt-10 md:ml-20 md:mb-10'>
      <p className='text-xl text-center text-white md:pb-1 md:mr-[770px]'>Copyright © ErBlan | All rights reserved.</p>
    </div>
  </div>
  )
}

export default AuthReset