import React from 'react'

const Footer = () => {
  return (
    <div className='w-screen h-20 bg-[#272728] mt-40 rounded-[20px]  '>
      <p className='md:text-xl text-center text-white md:pt-2 md:mr-[900px] mt-6'>
        Copyright © ErBlan | All rights reserved.
      </p> 
    </div>
  )
}

export default Footer
