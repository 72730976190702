import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter as Router} from "react-router-dom";
import {createStore} from 'redux'
import myReducers from "./context/reducers";
import { Provider } from "react-redux";
import App from './App';

const myStore = createStore(myReducers, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <Router>
      <Provider store={myStore}>
            <App />
       </Provider> 
     </Router>
  </React.StrictMode>
);
