import React from 'react'
import { HomeInfo, NavBar, Footer } from '../component'
import { motion } from 'framer-motion'
import {  textPop } from '../animations/index';

const Home = () => {
  return (
    <motion.main {...textPop} className='w-screen min-h-screen flex items-center justify-start flex-col bg-primary bg-cover bg-[url("https://media.threatpost.com/wp-content/uploads/sites/103/2020/11/12130957/darknet-e1605204611698.jpg")]'>
        <NavBar />
        <div>
            <HomeInfo />
        </div>
        <Footer />
    </motion.main>
  )
}

export default Home