import React from 'react'

const LoginInput = ({placeHolder,  inputState, inputStateFunc, type}) => {
  
  return (
    <div className="flex mt-1 w-[300px] h-[60px] bg-gray-800 rounded-full border border-gray-400 md:w-[500px] md:h-[70px]">
       <input 
       type={type} 
       placeholder={placeHolder} 
       className='w-full h-full bg-transparent text-white text-lg font-semibold border-none outline-none ml-6'
       value={inputState}
       onChange={(e) => inputStateFunc(e.target.value)}
       />
    </div>
  )
}

export default LoginInput