import { combineReducers } from 'redux';
import userReducer from './userReducer';
import categoriesReducer from './categoryReducer';
import singleCategoryReducer from './singleCategoryReducer';
import signOutReducer from './signOutReducer';



const myReducers = combineReducers({
    user: userReducer,
    categories: categoriesReducer,
    single_category: singleCategoryReducer,
    sign_out: signOutReducer
    
 });

 export default myReducers;