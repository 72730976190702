import React from 'react'


const HomeInfo = () => {
  return (
    <div className='text-white p-5 md:w-[950px] md:h-[700px] bg-navBG rounded-[20px] w-[360px]'>
      <div className='p-x'>
        <h1 className='pt-10 text-2xl md:text-4xl font-bold'>Welcome to ErBlan private store!</h1>
        <p className='md:text-[20px] mt-5 text-gray-400'>Our store boasts a self-written engine, an anti-DDoS system, and a bulletproof server. Don't journal!</p>

        <h3 className='text-2xl font-bold pt-10'>
        Attention! New return format. After purchasing the item, you will have a return countdown, at the moment it is
        </h3>

        <div className='p-7'>
            <ol className='list-decimal md:text-[20px] text-gray-400 leading-8 '>
                <li>Персональные данные клиентов хранятся в базе данных с нестандартным шифрованием и гарантированной безопасностью.</li>
                <li>Запрещена передача своих аккаунтов и ссылка на магазин 3-м лицам</li>
                <li>For all questions, please contact the chat directly in the store, or by Telegram: {" "}
                  <a className="hover:underline" target='blank' href='https://t.me/erblansupportpage'>
                    @erblansupport
                  </a>
                </li>
                <li>Replacing the invalid - return the cost account to an account in your personal account</li>
                <li>Accounts inactive for a month are - deleted for security reasons!</li>
                <li>За несоблюдение правил - удаление аккаунта без права восстановления.</li>
            </ol>
        </div>

        <h3 className='md:mt-6 text-2xl mb-10'>
        СОГЛАСНО ПРАВИЛАМ ВОЗВРАТА НАПИСАТЬ И УКАЗЫВАТЬ В ЖАБРАХ ПЕРЕД ПОКУПКОЙ!
        </h3>
      </div>
     
    </div>
  )
}

export default HomeInfo
