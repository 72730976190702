import React,{useEffect, useState} from 'react'
import { NavBar, Footer, HistroyCard } from '../component';
import { motion } from 'framer-motion';
import { slideTop } from '../animations';
import axios from 'axios';
import { baseURL } from '../Api';
import { useSelector } from 'react-redux';

const History = () => {
    const [data, setData] = useState([])
    const [userName, setuserName] = useState('')
    const [userEmail, setuserEmail] = useState('')
    const [totalPurchase, setTotalPurchase] = useState('')
    const user = useSelector(state => state?.user)

    useEffect(() => {
      const fetchData = async () => {
        try {
          if (user && user.token) { // Check if user is not null and has a token
            const res = await axios.get(`${baseURL}account/history/`, {
              headers: { Authorization: `Token ${user?.token}` }
            });
            //console.log(res.data)
            setData(res?.data?.invoices);
            setuserEmail(res?.data?.email);
            setuserName(res?.data?.username);
            setTotalPurchase(res?.data?.total_products);
          }
        } catch (error) {
          console.log("this is the Error", error);
        }
      }

      fetchData();
    }, [user]);
  
  return (
    <div className='md:w-screen min-h-screen flex items-center flex-col bg-primary bg-cover bg-[url("https://media.threatpost.com/wp-content/uploads/sites/103/2020/11/12130957/darknet-e1605204611698.jpg")]'>
       <NavBar />
         <motion.div {...slideTop} className='flex md:flex-row flex-col items-center  text-white md:mt-[150px] md:gap-10 gap-5 mt-10 p-10'>
             <h1 className='md:text-6xl font-bold text-2xl'>Purchase History</h1>
        
          <div className='md:ml-20  flex flex-col md:w-[500px]'>
            <p className='text-[15px]'>EMAIL ACCESS LOGIN(USER & PW) - SSN + DOB - WIRE & ZELLE ENABLED - COOKIES 
                - IP/UA - AN/RN - PHONE# - RDP ACCESS CARRIER PIN - DL - CVV/EXP - BILLPAY OFF
            </p>

              <div className='mt-3'>
                  <p className='text-[15px] font-bold'>
                      User: {userName}
                  </p>
                  <p className='text-[15px] font-bold'>
                      Email: {userEmail}
                  </p>
                  <p className='text-[15px] font-bold'>
                      Total Purchase: {totalPurchase}
                  </p>
              </div>
          </div>

         </motion.div>

        <div className='text-white md:items-center md:justify-center font-bold text-[24px] flex flex-col pt-5'>
             {/*Div for Cards*/}
            {data?.length > 0 ? (
                data.map((item, index) => (
                  <HistroyCard key={index} data={item} />
                ))
              ) : (
                <p className='text-white m-20'>No History available.</p>
              )}
         </div>
       <Footer />
    </div>
  )
}

export default History
