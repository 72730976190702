export const setAllCategories = (categories) => {
    return {
        type: "SET_ALL_CATEGORIES",
        categories: categories,
    };
}


export const getAllCategories = () => {
    return {
        type: "GET_ALL_CATEGORIES",
    };
};
