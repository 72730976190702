import React, { useState, useEffect } from 'react'
import { useLocation } from "react-router-dom";
import { LoginInput, MainLoader } from '.';
import { FaEnvelope, FaLock, } from "react-icons/fa";
import { resetPassword, resetPasswordConfirm } from '../Api';

const ResetPassword = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [Token, setToken] = useState('')

    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const token = params.get("token");

        if (token) {
            setToken(token)
          }
      }, []);

      const handleResetComfirm = async () => {
        setLoading(true);
        const payLoad = {
            token: Token,
            password
        }
        await resetPasswordConfirm(payLoad).then((res) => {
            if (res.status === 200) {
                setLoading(false);
                alert("Password reset successful. Please log in.");
                setLoading(false);
                window.location.href = "/auth";
            } else {
                alert("An error occurred while resetting password.");
                setLoading(false);
            }
        });
      }

      const handleReset = async () => {
        setLoading(true);
        const payLoad = {
            email: email
        }
        await resetPassword(payLoad).then((res) => {
            if (res.status === 200) {
                alert(`Check your email:${email} for a link to reset password.`);
                setLoading(false);
            } else {
                alert("An error occurred while resetting password.");
                setLoading(false);
            }
        });
      }



    return (
      <div className='relative flex flex-row justify-center mt-3 w-[350px] h-[600px] md:w-[650px]  md:h-[700px] rounded-[30px] overflow-hidden box'>
          <div className=''>
            <h1 className='text-white text-2xl font-bold text-center mt-10 md:text-5xl'>Welcome back</h1>
            <div className='flex flex-col ml-[150px] gap-2 mt-5 mr-[135px] md:mt-10 md:gap-3 md:mr-[120px]'>
             {!Token && (
              <>
                <div className='text-blue-600 mt-3 flex flex-row'><FaEnvelope size={20}/><p className='text-white font-bold ml-3 text-1xl'>Email</p></div>
                <LoginInput 
                  placeHolder={"Enter your email address"} 
                  inputState={email} 
                  inputStateFunc={setEmail} 
                  type="email" 
                />
              </>
             )}
            {Token && (
                <>
                  <div className='text-yellow-500 mt-3 flex flex-row'><FaLock size={20} />
                  <p className='text-white font-bold ml-3 text-1xl'>New Password</p>
                  </div>
                  <LoginInput
                    placeHolder={"Enter new password"}
                    inputState={password}
                    inputStateFunc={setPassword}
                    type="password"
                  />
                </>    
              )}
              <div onClick={Token ? handleResetComfirm : handleReset} className='flex bg-white rounded-full hover:cursor-pointer justify-center items-center hover:bg-red-500 text-white w-[300px] h-[60px] mt-5 md:w-[500px] md:h-[70px] md:mt-10'>
                {loading ? (
                 <MainLoader />
                ) : (
                  <h3 className='font-bold text-black hover:text-white'>
                    {Token ? 'Reset' : 'Initialize Password Reset'}
                  </h3>
                )}
              </div>
            </div>
          </div>
        </div>
  )
}

export default ResetPassword