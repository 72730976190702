import React from 'react'
import { motion } from 'framer-motion'
import { slideTopDelay } from '../animations/index';
import axios from "axios";
import {baseURL} from "../Api";
import {useSelector} from "react-redux";

const HistroyCard = ({data}) => {
    const user = useSelector(state => state?.user)

    console.log(data)

        const checkStatus = async () => {
            try {
                if (user && user.token) { // Check if user is not null and has a token
                    const res = await axios.get(`${baseURL}pay/decrypt/${data?.id}/`, {
                        headers: { Authorization: `Token ${user?.token}` }
                    });
                    if (res.status === 200) {
                        alert('Decryptor purchased.');
                    }
                }
            } catch (error) {
                console.log("this is the Error", error);
            }
        }
        
    return (
        <div className={'px-6'}>
             <motion.div {...slideTopDelay} className='w-full p-20 bg-[#212123] mt-20 rounded-[20px] flex flex-row'>

                     <div className='flex flex-col items-center justify-between md:flex-row gap-5'>
                         <p className='text-2xl font-bold text-green-600 flex items-center'>
                             ${data?.product.price}
                         </p>
                         <p className='text-[15px] md:w-3/6 w-6/6  text-white'>{data?.product.Info}</p>

                         <p className='text-2xl font-bold text-white'>
                             $ {data?.product.Balance}
                         </p>

                         <div
                             className='flex items-center justify-center w-[120px] p-3 rounded-[30px] text-white bg-red-500 font-bold hover:cursor-pointer '>
                             <p>Paid</p>
                         </div>

                         {data?.decrypted === true ? (
                             <div
                                 onClick={checkStatus}
                                 className='flex items-center justify-center p-3 rounded-[30px] text-white bg-green-400 font-bold hover:cursor-pointer '>
                                 <p>Decrypted</p>
                             </div>
                         ) : (
                             <div
                                 className='flex items-center justify-center p-5 rounded-[30px] text-white bg-green-400 font-bold hover:cursor-pointer '>
                                 <p>
                                     <a href={'/bank/extraction'}>
                                         Decrypt
                                     </a>
                                 </p>
                             </div>
                         )}
                     </div>
             </motion.div>

        </div>
    )
}

export default HistroyCard
