import React from 'react'
import { LoginInput, MainLoader } from '.';
import { useState } from 'react';
import { FaEnvelope, FaLock, FaUser } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { createUser } from '../Api';
import { useDispatch } from 'react-redux';
import { setUserDetails } from '../context/actions/userAction';

const SignUp = () => {
    const [userName, setUserName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch()
    const navigate = useNavigate()
   
    const handleSignUp = async () => {
      const data = {
        username: userName,
        email: userEmail,
        password: password,
      };
    
     
      if (userEmail !== "" && password !== "" && password !=="") {
        try {
          setLoading(true)
          const res = await createUser(data);
    
          if (res.status === 201) {
            navigate('/auth')
            // Save user data and session token in session storage
            localStorage.setItem('user', JSON.stringify(res.data));
            localStorage.setItem('sessionToken', JSON.stringify(res.data.token));
            dispatch(setUserDetails(res.data));
            alert("Sign Up Successful.");
          } else {
            alert("Sign Up failed. Email Taken.");
          }
        } catch (error) {
          alert("Network Error: Try credentials on Login Page.");
          console.error("Login error:", error);
        } finally {
          setInterval(() => {
            setLoading(false);
          }, 2000);
        }
      } else {
        alert("Input Fields cannot be blank!");
      }
      
    };
    
  return (
    <div>
      <div>
      <div className='relative flex flex-row justify-center mt-1 w-[350px] h-[700px] md:w-[650px]  md:h-[870px] rounded-[30px] overflow-hidden box'>
          <div className=''>
            <h1 className='text-white text-2xl font-bold text-center mt-5 md:text-5xl'>Welcome back</h1>
            <div className='flex flex-col ml-[150px] gap-2 mt-5 mr-[135px] md:mt-10 md:gap-3 md:mr-[120px]'>
            <div className='text-red-500 mt-5 flex flex-row'><FaUser size={20}/><p className='text-white font-bold ml-3 text-1xl'>Username</p></div>
            <LoginInput 
                placeHolder={"Enter your user name"} 
                inputState={userName} 
                inputStateFunc={setUserName} 
                type="username" 
              />
               <div className='text-blue-600 mt-3 flex flex-row'><FaEnvelope size={20}/><p className='text-white font-bold ml-3 text-1xl'>Email</p></div>
              <LoginInput 
                placeHolder={"Enter your email address"} 
                inputState={userEmail} 
                inputStateFunc={setUserEmail} 
                type="email" 
              />
               <div className='text-yellow-500 mt-3 flex flex-row'><FaLock size={20}/> <p className='text-white font-bold ml-3 text-1xl'>Password</p></div>
              <LoginInput 
                placeHolder={"Enter your password"} 
                inputState={password} 
                inputStateFunc={setPassword} 
                type="password" 
              />
              <div className='flex items-center mt-5  md:mt-10 md:ml-5'>
                <input 
                  type="checkbox" 
                  id="rememberMe" 
                  className='mr-2 checkbox-lg' 
                />
                <label className='text-white text-ld md:text-xl'>Remember me</label>
              </div>
              <div onClick={handleSignUp} className='flex bg-white rounded-full justify-center items-center hover:bg-red-500 hover:text-white w-[300px] h-[60px] mt-5 md:w-[500px] md:h-[70px] md:mt-10'>
              {loading ? (
                <MainLoader />
              ) : (
                <h3 className='font-bold  hover:text-white hover:cursor-pointer'> Sign Up</h3>
              )}
              </div>
              <div className='flex flex-row justify-center items-center mt-5 md:mt-10'>
                <div className=' w-[120px] md:w-[200px] mr-2 border-solid border-[0.5px] border-gray-400'></div>
                <p className='text-white text-l md:text-xl'>OR</p>
                <div className='w-[120px] md:w-[200px] ml-2 border-solid border-[0.5px] border-gray-400'></div>
              </div>
              <div className='flex justify-center items-center mt-5'>
              <p className='text-gray-300 md:text-lg'>Already have an account?</p> 
              <p className='text-white md:text-lg ml-2 hover:text-red-500 cursor-pointer'
                onClick={() => navigate('/auth')}
                >
                  Login here
               </p>
              </div>
            </div>
          </div>
        </div>
       
      </div>
    </div>
  )
}

export default SignUp