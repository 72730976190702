import React, { useEffect, useState } from 'react';
import { NavBar, Footer, Card } from '../component';
import { useDispatch, useSelector } from 'react-redux';
import { listParticularCategory } from '../Api';
import { setSingleCategory } from '../context/actions/singleCategoryAction';
import { useParams} from 'react-router-dom';
import { motion } from 'framer-motion';
import { slideTop } from '../animations';
import MainLoader from '../component/MainLoader';
import { DataTable } from '../component/TableV2';


const Bank = () => {
  const { slug } = useParams();
  const category = useSelector(state => state.single_category);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      await listParticularCategory(slug).then((data) => {
        //console.log(data)
        dispatch(setSingleCategory(data));
      });
      setInterval(() => {
        setLoading(false);
      }, 2000);
    };
  
    fetchData();
  }, [slug, dispatch]);



  return (
    <div className='md:w-screen w-full min-h-screen flex items-center flex-col bg-primary bg-cover bg-[url("https://media.threatpost.com/wp-content/uploads/sites/103/2020/11/12130957/darknet-e1605204611698.jpg")]'>
      <NavBar />
      {loading ? (
       <div className='flex justify-center items-center mt-[190px]'>
          <MainLoader /> 
       </div>
      ) : category ? (
        <div className='w-11/12'>
          <motion.div {...slideTop} className='flex md:flex-row flex-col text-white md:mt-[150px] md:gap-10 gap-5 mt-8 px-14 md:px-10'>
            <h1 className='md:text-6xl font-bold text-3xl capitalize'>{category[0].name}</h1>
            <div className='md:ml-[270px] md:w-[500px] w-[300px]'>
              <p className='text-[20px]'>{category[0].Info}</p>
            </div>
          </motion.div>
          {/* <motion.div {...slideTop} className='text-white flex md:gap-[250px] gap-5 mt-20 md:mr-[350px] mr-[10px] font-bold text-2xl'>
            <p>Price</p>
            <div className='flex flex-row md:gap-[170px] gap-5'>
              <p>Description</p>
              <p>Balance</p>
            </div>
          </motion.div>
          {category.length > 0 ? (
            category.map((item, index) => (
              <Card key={index} category={item} />
            ))
          ) : (
            <p className='text-white m-20'>No Accounts available.</p>
          )} */}

          <DataTable data={category} />
        </div>
      ) : null}
      <Footer />
    </div>
  );
};

export default Bank;
