import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { listCategory } from '../Api';
import { useDispatch } from 'react-redux';
import { setAllCategories } from '../context/actions/categoriesAction';
import MainLoader from './MainLoader';

const BankDrop = () => {
  const [category, setCategory] = useState(null);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const res = await listCategory();
        dispatch(setAllCategories(res));
        setCategory(res);
      } catch (error) {
        console.log('This is the error', error);
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      }
    };

    fetchData();
  }, [dispatch]);

  const groupByLocation = (data) => {
    return data.reduce((acc, item) => {
      if (!acc[item.location]) {
        acc[item.location] = [];
      }
      acc[item.location].push(item);
      return acc;
    }, {});
  };

  const groupedCategory = category ? groupByLocation(category) : {};

  return (
    <div className="bg-[#2c2c31] w-[650px] h-[400px] overflow-y-auto rounded-[20px]">
      <div className="grid grid-cols-1 gap-4 p-10">
        {loading ? (
          <div className="flex justify-center items-center">
            <MainLoader />
          </div>
        ) : category && Object.keys(groupedCategory).length > 0 ? (
          Object.keys(groupedCategory).map((location, idx) => (
            <div key={idx}>
              <h2 className="text-red-500 text-2xl mb-4">{location}</h2>
              <div className="grid grid-cols-3 gap-4">
                {groupedCategory[location].map((data, i) => (
                  <Link to={`/bank/${data.slug}`} className="hover:text-red-500" key={i}>
                    {data?.name}
                  </Link>
                ))}
              </div>
            </div>
          ))
        ) : (
          <p className="text-white flex justify-center items-center text-4xl">No Banks</p>
        )}
      </div>
    </div>
  );
};

export default BankDrop;
