const singleCategoryReducer = (state = null, action) => {
    switch(action.type){
        case "GET_SINGLE_CATEGORY":
            return state;

        case "SET_SINGLE_CATEGORY":
            return action.category;

        default:
            return state;
    }
}

export default singleCategoryReducer