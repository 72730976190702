import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../Assets/styles/main.css';

const Main = () => {
  const navigate = useNavigate()

  return (
    <div className='flex justify-center items-center h-screen'>
      <div className="main bg-cover bg-center fixed top-0 left-0 w-screen h-screen">
        <div className='fixed z-50 inset-0 backdrop-opacity-10 backdrop-invert bg-black/30'>
          <div className='auth flex h-screen items-center justify-cente gap-[100px] md:ml-[200px]'>
           
            {/* Use Link to navigate to /auth/login and /auth/signup */}
            <h1 className="text-white text-[35px] font-bold ml-7 md:text-6xl" onClick={() => navigate("/auth")}>
               Login
            </h1>
          
           <h1  className="text-white text-[35px] font-bold  md:text-6xl"  onClick={() => navigate("/signupauth")}>
              Sign-up
           </h1>
          </div>

        </div>
        <div className='flex justify-center h-screen'>
          <div className='flex justify-center items-end  md:mb-[-60px]'>
              <div className='flex items-end'>
                <h1 className='text-gray-100/20 text-[60px] font-bold text-opacity-5 md:text-[210px]'>ErBlan</h1>
              </div>
           </div>
          <div className='absolute bottom-[100px] right-[-50px] text-white text-[15px] rotate-90  ml-auto'>ErBlan © 2015-2024</div>
       </div>
      </div>

    </div>
  );
};

export default Main;
