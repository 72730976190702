import React,{useEffect, useState} from 'react';
import '../Assets/styles/login.css';
import { LoginInput, MainLoader } from '.';
import { FaEnvelope, FaLock, } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { loginUser } from '../Api';
import { useDispatch } from 'react-redux';
import { setUserDetails } from '../context/actions/userAction';
import { useCallback } from 'react';


const Login = ( ) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false)


  //const user = useSelector(state => state.user)

  const dispatch = useDispatch()

  const navigate = useNavigate()

  const storedEmail = localStorage.getItem('email');
  const storedPassword = localStorage.getItem('password');
  const userInStorage = localStorage.getItem('user')

  useEffect(() => {
    if( userInStorage  !== null ){
      navigate("/home", { replace: true})
    }
  }, [navigate, userInStorage])


  const handleLogin = useCallback (async () => {
    const data = {
      email: email,
      password: password
    };
  
    if (email !== "" && password !== ""  ) {
      try {
        setLoading(true)
        const res = await loginUser(data);
  
        if (res.status === 200) {
          // Save user data and session token in session storage
          localStorage.setItem('user', JSON.stringify(res.data));
          localStorage.setItem('sessionToken', JSON.stringify(res.data.token));
          dispatch(setUserDetails(res.data));

          if (rememberMe === true) {
            localStorage.setItem('email', email);
            localStorage.setItem('password', password);
          } else {
            localStorage.removeItem("email");
            localStorage.removeItem("password");
          }
  
  
          // Navigate to the home page
          navigate("/home");
        } else {
          alert("Login failed. Please check your credentials.");
        }
      } catch (error) {
        console.error("Login error:", error);
        alert("Login failed. Please check your credentials.");
     } finally {
          setInterval(() => {
            setLoading(false);
          }, 3000);
        }
    } else if(storedEmail == null || storedPassword == null) {
      alert("Input Fields cannot be blank!");
    }
  },[dispatch, navigate,rememberMe, email, password]);

  //Remeber Me
  useEffect(() => {
    if (storedEmail !== null && storedPassword !== null) {
      const autoLog = async () => {
        // Pass the stored email and password to the handleLogin function
        const res = await handleLogin(storedEmail, storedPassword);
        console.log(res)
      };
  
      // Call the autoLog function
      autoLog();
    }
  }, [navigate, handleLogin, storedEmail, storedPassword]);
   

  return (

       <div className='relative flex flex-row justify-center mt-3 w-[350px] h-[600px] md:w-[650px]  md:h-[850px] rounded-[30px] overflow-hidden box'>
          <div className=''>
            <h1 className='text-white text-2xl font-bold text-center mt-10 md:text-5xl'>Welcome back</h1>
            <div className='flex flex-col ml-[150px] gap-2 mt-5 mr-[135px] md:mt-10 md:gap-3 md:mr-[120px]'>
             <div className='text-blue-600 mt-3 flex flex-row'><FaEnvelope size={20}/><p className='text-white font-bold ml-3 text-1xl'>Email</p></div>
              <LoginInput 
                placeHolder={"Enter your email address"} 
                inputState={email} 
                inputStateFunc={setEmail} 
                type="email" 
              />

              <div className='text-yellow-500 mt-3 flex flex-row'><FaLock size={20}/> <p className='text-white font-bold ml-3 text-1xl'>Password</p></div>
              <LoginInput 
                placeHolder={"Enter your password"} 
                inputState={password} 
                inputStateFunc={setPassword} 
                type="password" 
              />
                <div className='flex items-center justify-between mt-5  md:mt-10 md:ml-5'>
                  <div>
                    <input 
                      type="checkbox" 
                      id="rememberMe" 
                      className='mr-2 checkbox-lg'
                      checked={rememberMe}
                      onChange={(e) => setRememberMe(e.target.checked)} 
                    />
                    <label className='text-white text-ld md:text-xl'>Remember me</label>
                  </div>
                  <p className='text-white hover:underline cursor-pointer'>
                    <a href="/reset-password">Forgot password ?</a>
                  </p>
                </div>

              <div className='flex bg-white rounded-full justify-center items-center hover:bg-red-500 text-white w-[300px] h-[60px] mt-5 md:w-[500px] md:h-[70px] md:mt-10' onClick={handleLogin}>
                {loading ? (
                 <MainLoader />
                ) : (
                  <h3 className='font-bold text-black hover:text-white'> Login</h3>
                )}
              </div>
              <div className='flex flex-row justify-center items-center mt-5 md:mt-10'>
                <div className=' w-[120px] md:w-[200px] mr-2 border-solid border-[0.5px] border-gray-400'></div>
                <p className='text-white text-l md:text-xl'>OR</p>
                <div className='w-[120px] md:w-[200px] ml-2 border-solid border-[0.5px] border-gray-400'></div>
              </div>
              <div className='flex justify-center items-center mt-5'>
              <p className='text-gray-300 md:text-lg'>Don't have an account?</p> 
                <p className='text-white text-lg ml-2 hover:text-red-500 cursor-pointer'
                  onClick={() => navigate('/signupauth')}
                >
                   Sign up today
                 </p>
              </div>
            </div>
          </div>
        </div>
  )
}

export default Login;
