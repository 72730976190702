export const fadeInOut = {
    initial: {opacity: 0},
    animate: {opacity: 1},
    exit: {opacity: 0}
 };

 export const buttonClick = {
  whileTap: {scale: 0.95},
};


export const textPop = {
    initial: {y: -50 },
    animate: { y: 0 },
    transition: { duration: 0.5 }
}


export const slideTop = {
  initial : {opacity: 0, y : 30},
  animate : {opacity: 1, y : 0},
  exit:     {opacity: 0, y : 30},
  transition: { duration: 0.3, delay:  0.15 },
}

export const slideTopDelay = {
    initial : {opacity: 0, y : 30},
    animate : {opacity: 1, y : 0},
    exit:     {opacity: 0, y : 30},
    transition: { duration: 0.3, delay: 0.25 },
  }

export const spinText = {
  animate : { rotate: 360 },
  transition : { duration: 4, ease: "easeInOut" }
}

export const imageWhileHover = {
   whileHover: {scale : 1.15}
}

export const staggerFadeInOut = (i) => {
  return {
    initial: {opacity:0, y: 50},
    animate: {opacity:1, y: 0},
    exit: {opacity:0, y: 50},
    transition: { duration: 0.3, delay: i * 0.15 },
    key: { i },
  }
}

export const slideIn = {
  initial : {opacity: 0, x : 30},
  animate : {opacity: 1, x : 0},
  exit:     {opacity: 0, x : 30}
}