import React,{useEffect, useState} from 'react';
import { NavBar, Footer, MainLoader } from '../component';
import { FaEnvelope } from 'react-icons/fa';
import { BiBitcoin } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { baseURL } from '../Api';
import { motion } from 'framer-motion';
import {  buttonClick, slideTop, slideTopDelay } from '../animations';
import ResetDialog  from "../component/ResetDialog";

const TopUp = () => {
  const user = useSelector(state => state.user)
   const [data, setData] = useState(null);
    const [status, setStatus] = useState(false);
    const [loading, setLoading] = useState(false)

   useEffect(() => {
    const fetchData = async () => {
      try {
          setLoading(true)
        if (user && user.token) { // Check if user is not null and has a token
          const res = await axios.get(`${baseURL}pay/add/`, {
            headers: { Authorization: `Token ${user.token}` }
          });
          if (res.status === 201) {
              setLoading(false)
              setStatus(true)
          } else {
              setStatus(false)
              setLoading(false)
          }

            setLoading(false)
            setData(res.data);
        }
      } catch (error) {
          setLoading(false)
        console.log("this is the Error", error);
      }
    }
  
    fetchData();
  }, [user]);

  const copyAddy = () => {
    const textToCopy = data?.addr; // Get the text to copy
  
    if (textToCopy) {
      // Create a textarea element to hold the text
      const textArea = document.createElement('textarea');
      textArea.value = textToCopy;
      textArea.style.position = 'fixed';
      document.body.appendChild(textArea);
      textArea.select();
  
      try {
        // Attempt to copy the text to the clipboard
        const successful = document.execCommand('copy');
        const message = successful ? 'Copied to clipboard' : 'Copy failed';
  
        // You can display a message or handle it in any way you want
        alert(message);
      } catch (err) {
        console.error('Unable to copy', err);
      }
  
      // Clean up and remove the temporary textarea
      document.body.removeChild(textArea);
    }
  };
  
  // const sendEmail = () => {
  //   const recipientEmail = 'recipient@example.com'; // Replace with the recipient's email address
  //   const subject = 'Your email subject';
  //   const body = 'Your email body text';
  
  //   const mailtoLink = `mailto:${recipientEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  
  //   window.location.href = mailtoLink;
  // };

  const msgTelegram = () => {
    window.location.href = 'https://t.me/erblansupportpage';
  };
    

  return (
    <div className='md:w-screen min-h-screen flex items-center flex-col bg-primary'>
      <NavBar />
      <div className='flex md:flex-row justify-between md:gap-10 md:p-20 p-10 flex-col'>
        {/* Left */}
          <motion.div {...slideTop} className='flex flex-col items-center'>
              <h1 className='md:text-4xl text-white mt-5 font-bold text-xl'>Welcome, {user?.username} </h1>

              <motion.div
                  className=' p-3 h-[130px] mt-7   bg-[#212123] rounded-[30px] flex justify-center items-center gap-5 hover:text-red-400 hover:cursor-pointer'
                  onClick={msgTelegram}
                  {...buttonClick}
              >
                  <div className='rounded-full p-3 w-2/6 h-[60px] bg-[#321F21] flex items-center justify-center'>
                      <FaEnvelope size={30} className='text-red-600'/>
                  </div>
                  <h3 className='text-white font-bold md:text-[20px] text-[16px]'>Send us a message on Telegram</h3>

              </motion.div>

              <motion.div
                  className=' p-10 mt-7 md:w-[400px] w-[350px]   bg-[#212123] rounded-[30px] flex  items-center gap-5 hover:cursor-pointer'

              >
                  <div className={'flex flex-col'}>
                      <h1 className={'text-white font-bold md:text-[20px] mb-3 text-[16px]'}>Profile Info.</h1>
                      <div className={'space-y-2'}>
                          <p className='text-white'>Username: {user?.username}</p>
                          <p className='text-white'>Email: {user?.email}</p>
                          <p className='text-white'>Total Purchase: {user?.total_products}</p>
                      </div>

                      <ResetDialog />
                  </div>

              </motion.div>
          </motion.div>

          {/* Right */}
          <motion.div {...slideTopDelay} className='flex md:flex-col md:items-center mt-10'>
                  {loading ? (
                      <div>
                          <MainLoader />
                      </div>
                  ) : (
                      <div
                          className='md:w-[700px] flex-col px-6 w-[380px] md:h-[700px] bg-[#212123] rounded-[30px] flex justify-center p-10'
                      >
                          <h1 className='text-white md:text-6xl font-bold flex justify-center text-3xl'>Top-Up
                              Balance
                          </h1>
                          {status === true ? (
                              <div className=''>
                                  <div className='mt-10 px-6 flex md:flex-row md:items-center flex-col'>
                                      <p className='text-white font-bold text-[15px] md:text-xl ml-5'>This is your bitcoin
                                          deposit
                                          address, send only bitcoin to this address. Sending any other token will result in
                                          loss of
                                          assets.</p>
                                      <img
                                          src={`https://www.bitcoinqrcodemaker.com/api/?style=bitcoin&address=${data?.addr}&color=1`}
                                          alt="QRCode Maker"
                                          className="mt-10 w-[250px] ml-5"
                                      />
                                  </div>

                                  <div className='flex items-center px-6 mt-5'>
                                      <BiBitcoin color='white' size={25}/>
                                      <p className='text-white ml-[-5px]'>itcoin Deposit Address</p>
                                  </div>


                                  <div className='mt-10'>
                                      <div>
                                          <p className='text-white font-bold text-[15px] md:text-lg'>{data?.addr}</p>
                                      </div>

                                      <div className='mt-4'>
                                        <p className='text-red-300'>
                                         'Payments can take hours to confirm, do not panic when such happens,
                                         if after 24 hours of payment there is no reflection, contact support.
                                        </p>
                                      </div>

                                      <div
                                          className='bg-white w-[170px] h-[70px] rounded-[35px] mt-5 flex justify-center items-center hover:cursor-pointer hover:bg-red-500 hover:text-white '
                                          onClick={copyAddy}>
                                          <p className='font-bold'>Copy Address</p>
                                      </div>
                                  </div>

                              </div>
                          ) : (
                              <div className={'flex items-center text-center justify-center p-6 font-bold text-red-500'}>
                                 <p>If BTC Address does not come up automatically Contact Support through Telegram.</p>
                              </div>
                          )}
                      </div>
                  )}
          </motion.div>
      </div>
        <Footer/>
    </div>
  );
};


export default TopUp
