
const initialState = {
    isLoggingOut: false, // New state to manage logging out
  };
  
const signOutReducer = (state = initialState, action) => {
    switch(action.type){
        case "SET_LOGGING_OUT":
            return {
                ...state,
                isLoggingOut: action.payload,
              };

        default:
            return state;
    }
}

export default signOutReducer
