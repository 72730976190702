import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { FaHome, FaFirstOrder, FaSignOutAlt, FaChevronDown, FaChevronUp, FaEnvelope } from "react-icons/fa";
import { BiBitcoin } from "react-icons/bi";
import { AiFillBank } from "react-icons/ai";
import { listCategory } from '../Api';
import { useDispatch, useSelector } from 'react-redux';
import { setAllCategories } from '../context/actions/categoriesAction';
import { baseURL } from '../Api';
import axios from 'axios';
import BalanceLoading from './BalanceLoading';
import { setLoggingOut } from '../context/actions/logOutAction';

const NavDrop = ({ isActive }) => {
  const [category, setCategory] = useState(null);
  const [isDrop, setIsDrop] = useState(false);
  const [balace, setBalace] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector(state => state.user);

  const handleBank = () => {
    // Toggle the isDrop state
    setIsDrop(!isDrop);
  };

  const handleLogout = async () => {
    try {
      dispatch(setLoggingOut(true))
      const res = await axios.get(`${baseURL}account/logout/`, {
        headers: { Authorization: `Token ${user.token}` }
      });

      if (res.status === 200) {
        localStorage.removeItem('sessionToken')
        localStorage.removeItem('sessionToken')
        localStorage.removeItem('user')
        localStorage.removeItem('email')
        localStorage.removeItem('password')
        navigate('/', { replace: true });
      }
    } catch (error) {
      alert("An error occurred. Please try again.");
    }finally {
      setTimeout(() => (
        dispatch(setLoggingOut(false))
      ), 1000)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await listCategory();
        dispatch(setAllCategories(res));
        setCategory(res);
      } catch (error) {
        console.log("This is the error", error);
      }
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    const handleBalance = async () => {
      try {
        if (user && user.token) {
          const res = await axios.get(`${baseURL}pay/balance/`, {
            headers: { Authorization: `Token ${user.token}` }
          });
          //console.log(res.data);
          setBalace(res.data);
        } 
      } catch (error) {
        console.log("An error occurred while fetching balance:", error);
      }
    }
  
    handleBalance();
  }, [user]);

  // const sendEmail = () => {
  //   const recipientEmail = 'recipient@example.com'; // Replace with the recipient's email address
  //   const subject = 'Your email subject';
  //   const body = 'Your email body text';
  
  //   const mailtoLink = `mailto:${recipientEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  
  //   window.location.href = mailtoLink;
  // };

  const msgTelegram = () => {
    window.location.href = 'https://t.me/erblansupportpage';
  };

  const groupByLocation = (data) => {
    return data.reduce((acc, item) => {
      if (!acc[item.location]) {
        acc[item.location] = [];
      }
      acc[item.location].push(item);
      return acc;
    }, {});
  };

  const groupedCategory = category ? groupByLocation(category) : {};

  return isActive ? (
    <div className='w-[300px] bg-[#212123] absolute top-32 left-12 md:mt-5 ml-[-5px] pb-5 rounded-[20px] h-auto'>
      <ul className='flex flex-col mt-7 ml-6 space-y-6'>
        <NavLink className='hover:text-red-500' to={"/home"}>
          <div className='flex items-center gap-1'>
            <FaHome />
            <span>Home</span>
          </div>
        </NavLink>

        <NavLink className='hover:text-red-500' to={"/history"}>
          <div className='flex items-center gap-1'>
            <FaFirstOrder />
            <span> History</span>
          </div>
        </NavLink>

        <NavLink className='hover:text-red-500' to={"/topup"}>
          <div className='flex items-center gap-1'>
            <BiBitcoin size={25} />
            <span>Top-up</span>
          </div>
        </NavLink>

        {isDrop === true ? (
          <NavLink className='hover:text-red-500' onClick={handleBank}>
            <div className='flex items-center gap-1'>
              <AiFillBank size={25} />
              <span>BANKS</span>
              <FaChevronUp />
            </div>
          </NavLink>
        ) : (
          <NavLink onClick={handleBank}>
            <div className='flex items-center gap-1'>
              <AiFillBank size={25} />
              <span>BANKS</span>
              <FaChevronDown />
            </div>
          </NavLink>
        )}

        {isDrop === true && category && (
          <div className='overflow-y-auto max-h-[200px]'>
             {Object.keys(groupedCategory).map((location, idx) => ( 
              <div key={idx}>
               <h2 className="text-red-500 text-xl mb-4">{location}</h2>
               {groupedCategory[location].map((data, i) => (
                <NavLink to={`/bank/${data.slug}`} key={i} className='hover:text-red-500'>
                  <div className='flex flex-cols-3 gap-1 p-2'>
                    <span>{data?.name}</span>
                  </div>
                </NavLink>
              ))}
              </div>
             ))}
          </div>
        )}

        <NavLink className='hover:text-red-500' 
          onClick={msgTelegram}
        >
          <div className='flex items-center gap-1'>
            <FaEnvelope />
            <span>Customer Care</span>
          </div>
        </NavLink>

        <NavLink className='hover:text-red-500' onClick={handleLogout}>
          <div className='flex items-center gap-1'>
            <FaSignOutAlt />
            <span>Logout</span>
          </div>
        </NavLink>

        <NavLink to={"/topup"}>
          <div className='flex justify-center items-center rounded-full w-[250px] h-[50px] bg-white hover:bg-red-500 hover:text-white'>
            <BiBitcoin color='#0D0D0E' size={20} />
            <p className='ml-[-3px] text-primary'>alance</p>
            {balace !== null ? (
                      <p className='text-primary ml-1'>${parseFloat(balace.balance).toFixed(2)}</p>
                    ) : (
                      <div className='ml-5'>
                        <BalanceLoading />
                      </div>
                    )}
          </div>
        </NavLink>
      </ul>
    </div>
  ) : null;
};

export default NavDrop;
