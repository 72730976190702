import React,{useState, useEffect} from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { FaHome, FaEnvelope, FaFirstOrder, FaSignOutAlt ,FaBars, FaChevronDown, FaChevronUp} from "react-icons/fa";
import { BiBitcoin, BiX  } from "react-icons/bi";
import { AiFillBank  } from "react-icons/ai";
import NavDrop from './NavDrop';
import BankDrop from './BankDrop';
import { useSelector, useDispatch } from 'react-redux';
import { baseURL } from '../Api';
import axios from 'axios';
import BalanceLoading from './BalanceLoading';
import { setLoggingOut } from '../context/actions/logOutAction';


const NavBar = () => {
  const [isActive, setisActive] = useState(false)
  const [isBank, setisBank] = useState(false)
  const [balace, setBalace] = useState(null)


  const navigate = useNavigate()
  const dispatch = useDispatch()

  const user = useSelector(state => state.user)
  const handleClick = () => {
  // Toggle the isActive state for the mobile menu
   setisActive(!isActive);
  }
   
 
 
    const handleBank = () => {
      // Toggle the isActive state
      setisBank(!isActive);
    };
  
  
  const handleLogout = async () => {
    try {
      dispatch(setLoggingOut(true))
      const res = await axios.get(`${baseURL}account/logout/`,{
        headers: { Authorization: `Token ${user.token}`}
      })
     //console.log(res)
      if (res.status === 200){
        //localStorage.removeItem('user')
        localStorage.removeItem('sessionToken')
        localStorage.removeItem('user')
        localStorage.removeItem('email')
        localStorage.removeItem('password')
        navigate('/')
      }
    } catch (error) {
      alert("An Error occured try again.")
    } finally {
      setTimeout(() => (
        dispatch(setLoggingOut(false))
      ), 1000)
    }
  }

  useEffect(() => {
    const handleBalance = async () => {
      try {
        if (user && user.token) {
          const res = await axios.get(`${baseURL}pay/balance/`, {
            headers: { Authorization: `Token ${user.token}` }
          });
          //console.log(res.data);
          setBalace(res.data);
        } 
      } catch (error) {
        console.log("An error occurred while fetching balance:", error);

      }
    }
  
    handleBalance();
  }, [user]);
  

  // const sendEmail = () => {
  //   const recipientEmail = 'recipient@example.com'; // Replace with the recipient's email address
  //   const subject = 'Your email subject';
  //   const body = 'Your email body text';
  
  //   const mailtoLink = `mailto:${recipientEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  
  //   window.location.href = mailtoLink;
  // };

  const msgTelegram = () => {
  window.location.href = 'https://t.me/erblansupportpage';
};

   

  return (
    <header className='text-white flex flex-col  justify-between mt-10 bg-[#212123] md:w-[1000px] h-[80px] rounded-full w-[360px] ml-2'> 
       <nav className='gap-4 md:gap-8'>
              
             <ul className='hidden md:flex flex-row gap-10 mt-7 ml-6 '>
                <NavLink className='hover:text-red-500' to={"/home"}>
                <div className='flex items-center  gap-1'>
                  <FaHome />
                  <span>Home</span>
                </div>
                </NavLink>

                <NavLink className='hover:text-red-500' to={ "/history"}>
                <div className='flex items-center gap-1'>
                  <FaFirstOrder />
                  <span> History</span>
                </div>
                </NavLink>

                <NavLink className='hover:text-red-500' to={"/topup"}>
                <div className='flex items-center gap-1'>
                   <BiBitcoin size={25} />
                  <span>Top-up</span> 
                </div> 
                </NavLink>

               {isBank === false ? (
                  <>
                  <NavLink className='hover:text-red-500' onMouseEnter={handleBank}>
                      <div className='flex items-center gap-1'>
                      <AiFillBank size={25} />
                      <span>BANKS</span>
                      <FaChevronDown />
                      </div>
                  </NavLink>
                  </>
               ) : (
                  <>
                  <NavLink className='hover:text-red-500' onMouseLeave={handleBank}>
                      <div className='flex items-center gap-1 text-red-500'>
                      <AiFillBank size={25} />
                      <span>BANKS</span>
                      <FaChevronUp />
                      </div>
                  </NavLink>

                  <div className='absolute mt-[35px]' 
                  onMouseEnter={() => setisBank(true)} 
                  onMouseLeave={() => setisBank(false)}>
                      <BankDrop />
                  </div>

                  </>
               )}

               <NavLink className='hover:text-red-500' 
                 onClick={msgTelegram}
                >
                  <div className='flex items-center gap-1'>
                    <FaEnvelope />
                    <span>Customer Care</span>
                  </div>
                </NavLink>

                <NavLink className='hover:text-red-500' onClick={handleLogout} >
                   <div className='flex items-center gap-1'>
                    <FaSignOutAlt />
                    <span>Logout</span>
                   </div>
                </NavLink>

                <NavLink  to={"/topup"}>
                   <div className='flex justify-center items-center rounded-full w-[170px] h-[60px] bg-white mt-[-17px] ml-7 hover:bg-red-500 hover:text-white'>
                    <BiBitcoin color='#0D0D0E' size={20}/>
                    <p className='ml-[-3px] text-primary'>alance</p>
                    {balace !== null ? (
                      <p className='text-primary ml-1'>${parseFloat(balace.balance).toFixed(2)}</p>
                    ) : (
                      <div className='ml-5'>
                        <BalanceLoading />
                      </div>
                    )}
                   </div>
                </NavLink>
             </ul>

             <div className='flex justify-center mt-7 ml-[250px] md:hidden' onClick={handleClick}>
             
             {isActive ? <BiX size={30} /> : <FaBars size={25} />}
              
             </div>

             <NavDrop isActive={isActive} />
             
       </nav>
    </header>
  )
}

export default NavBar
